<div class="modal-wrapper" cf-loader-container position="bottom">
  <div cf-modal-header (emitDismiss)="cancel()"></div>

  <div class="modal-body mt-0">
    <div class="row">
      <div class="col-12 d-flex align-items-center">
        <h4 class="mb-0">
          {{ 'meeting.group.select_schedule_modal_title' | translate }}
        </h4>
        <cf-question-hint
          [hint]="'meeting.group.edit_schedule_modal_hint' | translate"
          class="ms-2"
        ></cf-question-hint>
      </div>
      <div class="col-12 mb-4">
        <cf-text color="grey-70" size="sm">
          {{ 'meeting.group.select_schedule_modal_subtitle' | translate }}
        </cf-text>
        <cf-select-new
          [provider]="schedulesProvider"
          (selectChange)="copyFrom($any($event))"
          placeholder="Copy From"
          [formControl]="scheduleControl"
          class="mt-3 w-md-50"
          appendTo=".modal-wrapper"
        >
        </cf-select-new>
      </div>

      <div class="col-12" *ngIf="schedule">
        <div class="card p-xl-4 p-3">
          <cf-edit-schedule
            [hideNameControl]="true"
            [schedule]="schedule"
          ></cf-edit-schedule>
        </div>
      </div>

      <div class="col-12 mt-4" *ngIf="schedule">
        <h4>{{ 'calendars.title' | translate }}</h4>
        <cf-text size="sm" color="grey-60" class="mt-2 d-none d-xl-block">
          {{ 'calendars.schedule_hint' | translate }}
        </cf-text>

        <div
          class="row"
          *ngIf="calendarStoreService.calendars$ | async as calendars; else loaderTmp"
        >
          <div class="col-xl-6">
            <ng-container *ngIf="calendars.length">
              <cf-manage-calendars
                [calendars]="calendars"
                [schedule]="schedule.schedule"
                [saveOnChange]="false"
                appendTo=".modal-wrapper"
                class="mt-4"
              ></cf-manage-calendars>
            </ng-container>

            <cf-connect-google-buttons class="mt-4"></cf-connect-google-buttons>
          </div>
        </div>

        <ng-template #loaderTmp>
          <mat-spinner [diameter]="32" class="mx-auto mt-3"></mat-spinner>
        </ng-template>
      </div>
    </div>
  </div>

  <div class="modal-footer">
    <cf-button (click)="accept()" isLoadingBtn disableOnRequest>
      {{ 'create' | translate }}
    </cf-button>
  </div>
</div>
